<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
      
      <div class=" mb-4">
          <h2 class="text-left">{{tableSpanish}}</h2>
      </div>
  
    <div class="my-4 ">
      <!-- <b-avatar class="bg-avatar zoom" variant="transparent" size="4.5rem"><img src="../../assets/plus_img.png" alt=""></b-avatar> -->
        <!-- Selecciona un archivo -->
        
      <label class="pointer">
        <b-avatar class="bg-avatar zoom"  size="7rem"><img v-if="row.urlimagen == ''" src="../../assets/plus_img.png" alt=""><img v-else class="img-fluid" :src="row.urlimagen" alt=""> </b-avatar>
        <input id="file1" type="file" style="display: none;" accept=".jpg" @change="onFileSelected('perfil')" />
      </label>
     
    </div>


    <!-- data table -->
    <div class="container-fluid">
       
      <div class="row">
        <div class="col-md-11 p-md-3 py-md-0 pt-2 mb-5 p-0">
          <form @submit.prevent="saveData()">

            <div class="row">
              
              <div class="col-md-4 px-2 py-1">
                <input v-model="row.nombre" required type="text" class="form-control mb-2" placeholder="Nombre">
              </div>
              <div class="col-md-4 px-2 py-1">
                <input v-model="row.email" required type="email" class="form-control mb-2"  placeholder="Email">
              </div>
              <div class="col-md-4 px-2 py-1">
                <input v-model="row.telefono" required type="text" class="form-control mb-2"  placeholder="Teléfono">
              </div>
              <div class="col-md-4 px-2 py-1">
                <input v-model="row.clave" required type="password" class="form-control mb-2"  placeholder="Contraseña">
              </div>
              <div class="col-md-4 px-2 py-1">
                <input v-model="row.confirmaClave" required type="password" class="form-control mb-2"  placeholder="Confirmar contraseña">
              </div>
            </div>
            <button type="submit" class="btn btn-primary mt-3">Actualizar datos</button>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import FormVue from "@/components/Form.vue";
export default {
  components: {
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      img: 0,
      perPage: 25,
      currentPage: 1,
      filter: null,
      table: "users",
      singleTable: "user",
      tableSpanish: "Mi Perfil",
      items: [],
      row:{},
      user:{},
      file1: null,
      filename1: null,
      rol:0
    };
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem('user')) || {}
    this.rol = this.user.rol
    this.getData();
  },
  methods: {
    onFileSelected(type, item){
      this.file1 = event.target.files[0];
      this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
      this.filename1 = type + '_' + this.filename1+ '.jpg';
    },   
    async saveData() {
      console.log(this.row)
      if(this.row.clave!= this.row.confirmaClave){
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "la contraseña no es igual.",
        });
        return
      }
    try {


        delete this.row.confirmaClave
        let uploadImg
        this.$isLoading(true)        
        if(this.filename1!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename1 ,file: this.file1})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.urlimagen = 'https://naotravelco.com/img/' + this.filename1
          }
        }
        let data = this.row
        let id = this.row.id
        delete data.id
        if(this.filename!= null){
          let uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename ,file: this.file})
          if(uploadImg == true){
            console.log('----',uploadImg)
            data.urlimagen = '../img/' + this.filename
          }
        }
        let ruta
        if(this.rol==99){
          ruta = 'clientes/modify/' + this.user.id
        }
        else{
          ruta = 'usuarios/modify/' + this.user.id
        }
        let req = await this.$store.dispatch("put", {
          path: ruta,
          data: data
        });
        this.$isLoading(false)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos actualizados correctamente!.",
        });
        // this.getData();
      } catch (error) {
        console.log('error in savedata',error)
        this.$isLoading(false)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar.",
        });
        //alert(error);
      }
    },
    async getData() {
      try {
        let path = this.user.rol == 1 ? 'usuarios': 'clientes'
        let res = await this.$store.dispatch("get", { path: path + '/get/' + this.user.id});
        this.row = res
        this.row.confirmaClave = this.row.clave 
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>

<style>
</style>